import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import theme from '../utils/theme';

const Paging = styled.div`
  flex: 0 0 auto;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 1rem 0.3rem;

  div {
    display: inline-block;
    font-size: 1rem;
    position: relative;
    top: 0.12rem;
  }

  a {
    color: inherit;
    background-image: none;
    font-size: 0.8rem;

    :hover {
      color: ${theme.dark.greenLink};
    }
  }

  .prev {
    display: inline-block;
    width: 100%;
  }

  .next {
    display: inline-block;
    width: 100%;
  }
`;

const Spacer = styled.div`
  flex: 1 1 auto;
  margin: 0 0.3rem;
`;

const PrevPage = styled(Link)``;

const NextPage = styled(Link)`
  text-align: right;
`;

const Label = styled.span`
  display: inline-block;
  max-width: 10rem;
`;

const Pagination = props => {
  const { leftLink, leftText, rightLink, rightText } = props;

  return (
    <Paging>
      {leftLink && leftText && (
        <PrevPage to={leftLink}>
          <div className="prev">&#8672;</div>
          <Label>{leftText}</Label>
        </PrevPage>
      )}
      <Spacer />
      {rightLink && rightText && (
        <NextPage to={rightLink}>
          <div className="next">&#8674;</div>
          <Label>{rightText}</Label>
        </NextPage>
      )}
    </Paging>
  );
};

Pagination.propTypes = {
  leftLink: PropTypes.string,
  leftText: PropTypes.string,
  rightLink: PropTypes.string,
  rightText: PropTypes.string,
};

export default Pagination;
