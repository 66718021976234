import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from '@emotion/styled';
import kebabcase from 'lodash.kebabcase';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Tag } from '../components/tagcloud';
import Pagination from '../components/pagination';
import theme from '../utils/theme';

/*
  One single post on the list
*/
const ArticleLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  background-image: none;

  :hover {
    background-image: ${theme.light.underline};
  }
`;

const ArticleDate = styled.h5`
  display: inline;
  color: #606060;
`;

const ArticleHeader = styled.h3`
  display: inline;
`;

const ReadingTime = styled.h5`
  display: inline;
  color: #606060;
`;

const TagHolder = styled.div`
  a:first-of-type {
    margin-left: 0;
    padding-left: 0;
  }

  a {
    margin: 0.2rem;
    padding: 0.2rem 0 0.2rem 0.2rem;
  }
`;

const Post = ({ data }) => (
  <div>
    <ArticleHeader>
      <ArticleLink title={data.frontmatter.title} to={data.frontmatter.path}>
        {data.frontmatter.title}
      </ArticleLink>
    </ArticleHeader>
    <div>
      <ArticleDate>{data.frontmatter.date}</ArticleDate>
      <ReadingTime> - {data.fields.readingTime.text}</ReadingTime>
    </div>
    <TagHolder>
      {data.frontmatter.tags.map((tag, i) => (
        <Tag key={i} to={`/tags/${kebabcase(tag)}`}>
          <span className="tag-text">{tag}</span>
        </Tag>
      ))}
    </TagHolder>
    <p>
      <ArticleLink title={data.frontmatter.title} to={data.frontmatter.path}>
        {data.frontmatter.summary}
      </ArticleLink>
    </p>
  </div>
);

/*
  The rest of the page, including the list of posts
*/

const Content = styled.div`
  margin: 0 auto;
  height: 100%;
  padding: 1.45rem 1.0875rem;
`;

const Title = styled.h1`
  margin-bottom: 2rem;
`;

const PostList = styled.div``;

export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    const { currentPage, numPages } = this.props.pageContext;

    const index = currentPage - 1;
    const prevLink =
      (currentPage > 1 &&
        numPages > 1 &&
        (index === 1 ? `/blog` : `/blog/${index - 1}`)) ||
      null;
    const nextLink = (currentPage < numPages && `/blog/${index + 1}`) || null;

    return (
      <Layout
        main={
          <React.Fragment>
            <SEO
              title="Blog"
              keywords={['blog', 'posts', 'development', 'article']}
            />
            <Content>
              <Title>Blog</Title>
              <PostList>
                {posts.map(({ node }) => (
                  <Post key={node.id} data={node} />
                ))}
              </PostList>

              <Pagination
                leftLink={prevLink}
                leftText="Newer"
                rightLink={nextLink}
                rightText="Older"
              />
            </Content>
          </React.Fragment>
        }
      ></Layout>
    );
  }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            summary
            path
            date(formatString: "DD MMMM, YYYY")
            tags
          }
        }
      }
    }
  }
`;
